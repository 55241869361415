import React, { useEffect, useState, useMemo, useRef } from 'react'
import DatePicker from "react-datepicker";
import { useForm } from 'react-hook-form';
import FileSaver from "file-saver";
import Papa from 'papaparse'
import moment from "moment";
import EyeFill from 'remixicon-react/EyeFillIcon';
import Calendar2LineIcon from 'remixicon-react/Calendar2LineIcon';
import DownloadCloud2sLineIcon from 'remixicon-react/DownloadCloud2LineIcon';
import CheckboxCircleLine from 'remixicon-react/CheckboxCircleLineIcon';
import ArrowLeftLineIcon from 'remixicon-react/ArrowLeftLineIcon';
import CloseLineIcon from 'remixicon-react/CloseLineIcon';
import CheckLineIcon from 'remixicon-react/CheckLineIcon';
import { useDispatch, useSelector } from 'react-redux';
import SimPurchaseActions from '../../../redux/simPurchase/actions'
import CardLayout from '../../../components/utils/CardLayout'
import SideButtons from '../../../components/utils/SideButtons'
import SearchBar from '../../../components/utils/SearchBar'
import Table from '../../../components/utils/Table'
import Tag from '../../../components/utils/Tag'
import Button from '../../../components/utils/Button'
// import Style from './SimPurchase.module.css'
// import Alert from '../../../components/utils/Alert';
import Modal from '../../../components/utils/Model';
import { saveAs } from 'file-saver';

const dateFormat = process.env.REACT_APP_DATE_FORMAT;
const { getPhysicalSimAggregator, getInvoice, savePurchaseSim, validateSimData, resetSimPurchase, getSimPurchase, getSimPurchaseDetails, getSimTypeData } = SimPurchaseActions;

const SimPurchase = () => {
    const ref = useRef();
    const dispatch = useDispatch();
    const SimPurchaseState = useSelector((state) => state.simPurchase);
    const { simTypeList, savePurchaseMessage,validateSimDataLoading} = useSelector((state) => state.simPurchase);
    const [filterText, setFilterText] = useState("");
    const [showAddView, setShowAddView] = useState(false);
    const [csvPreview, setCsvPreview] = useState([]);
    const [purchaseDate, setPurchaseDate] = useState(moment().startOf('day').toDate());
    const [csvError, setCsvError] = useState("");
    const [duplicateData, setDuplicateSimData] = useState([]);
    const [validData, setValidSimData] = useState([]);
    const [showSimPurchaseDetail, setShowSimPurchaseDetail] = useState(false);
    const datePickerRef = useRef(null);
    const [simTableData, setSimTableData] = useState(false)
    const [showDownloadBtn, setShowDownloadBtn] = useState(false)
    const [watchSimType, setwatchSimType] = useState('')


    const { register, handleSubmit, formState: { errors }, reset, watch } = useForm();
    //const watchSimType = watch("simType")
    const sendPlanSimType = {
        "tableName": "sim",
        "fieldName": "SIMType"
    };


    useEffect(() => {
        dispatch(getSimPurchase())
        dispatch(getInvoice())
        dispatch(getSimTypeData(sendPlanSimType))
    }, []);

    useEffect(() => {
        if (savePurchaseMessage) {
            reset()
            setShowDownloadBtn(false)
        }
    }, [savePurchaseMessage]);

    const columns = [
        { displayName: '#', key: 'key' },
        { displayName: 'PURCHASE NUMBER', key: 'PurchaseNumber' },
        { displayName: 'INVOICE NUMBER', key: 'InvoiceNumber' },
        { displayName: 'PURCHASE TYPE', key: 'PurchaseType' },
        { displayName: 'PURCHASE DATE', key: 'PurchaseDate' },
        { displayName: 'PURCHASED BY(USER)', key: 'PurchasedBy' },
        { displayName: 'ACTION', key: 'Action' }
    ];

    const simDetailsColumns = [
        { displayName: '#', key: 'key' },
        { displayName: 'ICCID', key: 'ICCID' },
        { displayName: 'SIM TYPE', key: 'SIMType' },
        { displayName: 'MSISDN', key: 'MSISDN' },
        { displayName: 'SMDP ADDRESS', key: 'smdpAddress' },
        // { displayName: 'ACTIVATION DATE', key: 'activationDate' },
        { displayName: 'ACTIVATION CODE', key: 'activationCode' },
        { displayName: 'QR CODE', key: 'qrCode' },
        { displayName: 'ESIM CODE', key: 'esimCode' },
        // { displayName: 'IMSI PROFILE ID', key: 'imsiProfileId' },
        // { displayName: 'STATUS', key: 'status' },
        // { displayName: 'CURRENT DEALER', key: 'currentDealers' },
    ]
    const simdata = SimPurchaseState.getSimPurchase;
    const filteredItems = simdata && simdata.length > 0 && simdata.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );

    const dataSource = filteredItems && filteredItems.length > 0 && filteredItems.map((PurchaseItem, key) => {
        return {
            key: key + 1,
            PurchaseNumber: PurchaseItem.PurchaseNumber,
            InvoiceNumber: PurchaseItem.InvoiceNumber,
            PurchaseDate: moment(PurchaseItem.PurchaseDtTm).format(dateFormat),
            PurchasedBy: PurchaseItem.PurchasedBy,
            PurchaseType: <Tag color={`${PurchaseItem.PurchaseType === 'S' ? "badge-success" : "badge-danger"}`} title={PurchaseItem.PurchaseType === 'S' ? 'SIM' : 'MSISDN'} />,
            Action: <span data-toggle="tooltip" data-placement="top" title="View"><EyeFill size="1.2rem" style={{ color: "#0094b3", cursor: 'pointer' }} onClick={() => handleShowPurchaseDetails(PurchaseItem.ID)} /></span>,
        }
    });


    const simTypeChangeHandler = (data) => {
        setShowDownloadBtn(true)
        setSimTableData(false)
        ref.current.value = null;
        setwatchSimType(data.target.value)
    }



    const handleFilterChange = (value) => {
        setFilterText(value);
    };

    const handleShowAddPurchaseUi = () => {
        dispatch(getInvoice())
        dispatch(getPhysicalSimAggregator());
        setCsvPreview([]);
        setShowAddView(true);
    }

    const handleShowListPurchaseUi = () => {
        setShowAddView(false);
        setShowDownloadBtn(false)
        reset()
    }


    const excelColumns = watchSimType == 48 ? [
        { displayName: '#', key: 'key' },
        { displayName: 'SIM NUMBER', key: 'simNumber' },
        { displayName: 'MOBILE NUMBER', key: 'mobileNumber' },
        { displayName: 'STATUS', key: 'status' },
        { displayName: 'MESSAGE', key: 'message' },
    ] :
        [
            { displayName: '#', key: 'key' },
            { displayName: 'SIM NUMBER', key: 'simNumber' },
            { displayName: 'SMDP ADDRESS', key: 'smdpAddress' },
            { displayName: 'ACTIVATION CODE', key: 'activationCode' },
            { displayName: 'QR CODE', key: 'qrCode' },
            { displayName: 'E-SIM CODE', key: 'esimCode' },
            { displayName: 'STATUS', key: 'status' },
            { displayName: 'MESSAGE', key: 'message' },
        ]
        ;


    const excelDataSource = useMemo(() => {
        if (csvPreview && csvPreview.length > 0) {
            const previewData = csvPreview && csvPreview.length > 0 && csvPreview.filter(data => data != undefined).map((item, key) => {
                let isDuplicate = item.SerialNumber ? duplicateData.includes(item.SerialNumber) : '';
                let isValid = validData.includes(item.SerialNumber);
                let alreadyExist = SimPurchaseState.validateSimData ? SimPurchaseState.validateSimData.split(',') : [];
                let isAlreadyExist = alreadyExist.includes(item.SerialNumber);

                return {
                    key: key + 1,
                    simNumber: item.SerialNumber ? item.SerialNumber : '',
                    mobileNumber: item.MSISDN ? item.MSISDN : '',
                    smdpAddress: item.SMDPAddress ? item.SMDPAddress : '',
                    activationCode: item.ActivationCode ? item.ActivationCode : '',
                    qrCode: item.QRCode ? item.QRCode : '',
                    esimCode: item.EsimCode ? item.EsimCode : '',
                    status: <Tag color={`${!isDuplicate && isValid && !isAlreadyExist ? "badge-success" : "badge-danger"}`} title={!isDuplicate && isValid && !isAlreadyExist ? <CheckLineIcon /> : <CloseLineIcon />} />,
                    message: isDuplicate ? 'Duplicate' : isValid && !isAlreadyExist ? 'Valid' : isAlreadyExist ? 'Already Exist' : 'Invalid'
                }
            });
            return previewData;
        } else {
            return [];
        }
    }, [csvPreview, SimPurchaseState.validateSimData])


    const downloadSampleFile = () => {
        if (watchSimType == 48) {
            const csvFilePath = process.env.PUBLIC_URL + '/csvResources/p_sim_purchase_sample.csv';
            saveAs(csvFilePath, moment().format(dateFormat) + '_P_Sim_Purchase.csv');
            // FileSaver.saveAs(process.env.PUBLIC_URL + '/csvResources/p_sim_purchase_sample.csv', 'P_Sim_Purchase.csv');
        }
        else {
            const csvFilePath = process.env.PUBLIC_URL + '/csvResources/e_sim_purchase_sample.csv';
            saveAs(csvFilePath, moment().format(dateFormat) + '_E_Sim_Purchase.csv');
            //FileSaver.saveAs(process.env.PUBLIC_URL + '/csvResources/e_sim_purchase_sample.csv', 'E_Sim_Purchase.csv');
        }
    }

    const handleAddSimPurchase = (data) => {
        let request = {
            aggregator: data.aggregator,
            simType: data.simType,
            purchaseDate: moment(purchaseDate).format('YYYY-MM-DD'),
            purchaseType: 'S',
            purchaseNumber: SimPurchaseState.getInvoice.PurchaseNumber,
            invoiceNumber: SimPurchaseState.getInvoice.InvoiceNumber,
            simData: csvPreview,
        }
        dispatch(savePurchaseSim(request));
    }

    const handleCsvUpload = (e) => {
        setCsvError("");
        setSimTableData(true)
        const fileReader = new FileReader();
        let file = e.target.files[0];
        if (file) {
            fileReader.onload = async ({ target }) => {
                const csvOutput = Papa.parse(target.result, { header: true });
                const parsedData = csvOutput?.data;
                const columns = Object.keys(parsedData[0]);

                let previewData = [];
                if (columns[0] === 'SerialNumber' || columns[0] === 'QRCode') {
                    let validSimData = [];
                    const duplicateSimData = [];
                    if (columns[0] === 'SerialNumber') {
                        previewData = parsedData.filter((item) => item.SerialNumber != '');
                    }
                    else {
                        parsedData && parsedData.length > 0 && parsedData.filter(data => data != undefined && data != '').map((item) => {
                            if (item.QRCode.length > 0) {
                                let tempData = item.QRCode.split(',');
                                previewData.push({ "SerialNumber": tempData[0], "ActivationCode": tempData[1], "SMDPAddress": tempData[2], "QRCode": tempData[3], "EsimCode": tempData[4] });
                            }
                        });
                    }

                    previewData.filter(item => item != undefined).map((sim, key) => {
                        if (columns[0] === 'SerialNumber' && sim.SerialNumber.length === 19 && Number.isInteger(Number(sim.SerialNumber))) {
                            if (validSimData.includes(sim.SerialNumber)) {
                                if (columns[1] === 'MSISDN' && sim.MSISDN.length === 10 && Number.isInteger(Number(sim.MSISDN))) {
                                    duplicateSimData.push(sim.SerialNumber);
                                }
                                else {
                                    duplicateSimData.push(sim.SerialNumber);
                                }
                            }
                            else {
                                validSimData.push(sim.SerialNumber);
                            }
                        } else {
                            if (columns[0] === 'QRCode') {

                                if (sim && sim.QRCode && sim != undefined) {
                                    let eSimData = sim.QRCode.split(',');
                                    if (validSimData.includes(sim.SerialNumber)) {
                                        duplicateSimData.push(sim.SerialNumber);
                                    }
                                    else {
                                        validSimData.push(sim.SerialNumber);
                                    }
                                }
                            }
                            else {
                                setCsvError(`Column header's not matching with csv sample file`);
                            }
                        }
                    });

                    setValidSimData(validSimData);

                    if (duplicateSimData.length === 0 || duplicateSimData == '') {
                        if (validSimData.length > 0) {
                            const simValidationData = {
                                "simData": validSimData
                            }
                            dispatch(validateSimData(simValidationData));
                            setCsvPreview(previewData);
                        }
                        else {
                            setCsvError('No valid SIM to upload');
                        }
                    } else {
                        setDuplicateSimData(duplicateSimData)
                        setCsvError('Duplicate data in CSV file uploaded');
                    }


                } else {
                    setCsvError(`Column header's not matching with csv sample file`);
                }
            };
            fileReader.readAsText(file);
        }
    }


    const openDatePicker = () => {
        datePickerRef.current.setOpen(true);
    };

    const closeSuccessModal = () => {
        dispatch(getSimPurchase());
        setShowAddView(false);
        dispatch(resetSimPurchase());
    }

    const handleShowPurchaseDetails = (purchaseId) => {
        dispatch(getSimPurchaseDetails(purchaseId));
        setShowSimPurchaseDetail(true);
    }

    const simDetails = SimPurchaseState.getSimPurchaseDetails;
    const simDetailsFilteredItems = simDetails && simDetails.length > 0 && simDetails.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );


    const simDetailsDataSource = simDetailsFilteredItems && simDetailsFilteredItems.length > 0 && simDetailsFilteredItems.map((PurchaseItem, key) => {
        return {
            key: key + 1,
            ICCID: PurchaseItem.ICCID,
            MSISDN: PurchaseItem.MSISDN,
            activationDate: moment(PurchaseItem.PurchaseDtTm).format(dateFormat),
            qrCode: PurchaseItem.QRCode,
            imsiProfileId: PurchaseItem.PurchasedBy,
            status: <Tag color={`${PurchaseItem.Status == 32 ? "badge-success" : "badge-danger"}`} title={PurchaseItem.SimStatus} />,
            SIMType: PurchaseItem.SIMType == 48 ? 'Physical Sim' : 'E Sim',
            currentDealers: PurchaseItem.DealerName,
            activationCode: PurchaseItem.ActivationCode,
            smdpAddress: PurchaseItem.SMDPAddress,
            esimCode: PurchaseItem.EsimCode
        }
    });

    return (

        <>

            <Modal
                showModal={SimPurchaseState.savePurchaseSuccessModal}
                title="Success"
                onHide={closeSuccessModal}
                onSaveChanges={closeSuccessModal}
                showFooter={false}
                alignment='modal-dialog-centered'
            >
                <div style={Styles.modalContent}>
                    <CheckboxCircleLine size="4em" color='#00b894' />
                    <p className='text-dark'>{SimPurchaseState.savePurchaseMessage}</p>
                </div>

            </Modal>

            {
                !showAddView && !showSimPurchaseDetail ? <>
                    <CardLayout title="Sim Purchase">
                        <div className='row align-items-center'>
                            <div className='col-md-6  od-2'>
                                <SearchBar onFilter={handleFilterChange} filterText={filterText} />
                            </div>
                            <div className='col-md-6 text-right'>
                                <SideButtons title="Add Purchase" onClick={handleShowAddPurchaseUi} buttonStyle="primary" />
                            </div>
                        </div>
                        <Table columns={columns} loading={SimPurchaseState.getSimPurchaseLoading} data={dataSource} pagination={true} />
                    </CardLayout>
                </> : ''
            }

            {
                showAddView && !showSimPurchaseDetail ? <>
                    <CardLayout title="Add Sim Purchase">
                        {/* {SimPurchaseState.validateSimData != '' && SimPurchaseState.validateSimData != null ? <Alert type='alert-danger' text={`Duplicate SIM ${SimPurchaseState.validateSimData}`} /> : ''} */}
                        <div className='d-flex justify-content-end mb-2'>
                            <div>
                                <div className='d-flex justify-content-end'><Button icon={<DownloadCloud2sLineIcon color='#fff' className='mr-1' size={'0.9rem'} />} title="Download Sample File" disable={showDownloadBtn ? false : true} onClick={downloadSampleFile} buttonClass={`btn btn-success btn-sm mb-1 py-2`} buttonType='button' /></div>
                                <p style={{ fontSize: '13px', color: 'red' }}><span style={{ fontSize: '15px', fontWeight: '500' }}>Note : </span><i>Please select SIM type before sample file download </i></p>
                            </div>
                        </div>

                        <form className='form' onSubmit={handleSubmit(handleAddSimPurchase)}>
                            <div className="form-row">

                                <div className="col-md-4 col-lg-2 mb-2">
                                    <label>SIM Type</label>
                                    <select className="form-control" placeholder="Select" name="simType" {...register("simType", {
                                        required: true,

                                    })} onChange={simTypeChangeHandler}>
                                        <option value='' selected disabled key={0}>Select SIM</option>
                                        {simTypeList && simTypeList.length > 0 && simTypeList.map(simType => (
                                            <option value={simType.ID} key={simType.ID}>{simType.Value}</option>
                                        ))}
                                    </select>
                                    {errors.simType?.type === "required" && (<div className="invalid-feedback">SIM Type is required !</div>)}
                                </div>

                                <div className="col-md-4 col-lg-2 mb-2">
                                    <label>Aggregator</label>
                                    <select className="form-control" placeholder="Select" name="aggregator" {...register("aggregator", {
                                        required: true,
                                    })}>
                                        <option value='' selected disabled key={0}>Select Aggregator</option>
                                        {
                                            SimPurchaseState.physicalSimAggregator && SimPurchaseState.physicalSimAggregator.length > 0 ?
                                                SimPurchaseState.physicalSimAggregator.map((item, index) => {
                                                    return <option value={item.ID} key={item.ID}>{item.AggregatorName}</option>
                                                }) : ''
                                        }
                                    </select>
                                    {errors.aggregator?.type === "required" && (<div className="invalid-feedback">Aggregator is required !</div>)}
                                </div>

                                <div className="col-md-4 col-lg-2 mb-3 d-flex flex-column">
                                    <label>Purchase Date</label>
                                    <Calendar2LineIcon onClick={openDatePicker} size={'1.5rem'} style={{ cursor: 'pointer', position: 'absolute', 'top': '36px', right: '12px', zIndex: 1 }} />
                                    <DatePicker
                                        ref={datePickerRef}
                                        className="form-control"
                                        dateFormat="yyyy-MM-dd"
                                        name="fromDate"
                                        selected={purchaseDate}
                                        maxDate={purchaseDate}
                                        onChange={(date) => setPurchaseDate(date)}
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                        showMonthDropdown
                                        showYearDropdown
                                    />
                                </div>

                                <div className="col-md-4 col-lg-2 mb-3 d-flex flex-column">
                                    <label>Purchase Number</label>
                                    <input
                                        value={SimPurchaseState.getInvoice.PurchaseNumber}
                                        disabled={true}
                                        className="form-control"
                                        dateFormat="yyyy-MM-dd"
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                    />
                                </div>

                                <div className="col-md-4 col-lg-2 mb-3 d-flex flex-column">
                                    <label>Invoice Number</label>
                                    <input
                                        className="form-control"
                                        value={SimPurchaseState.getInvoice.InvoiceNumber}
                                        disabled={true}
                                        dateFormat="yyyy-MM-dd"
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                    // name="invoiceNumber" {...register("invoiceNumber", {
                                    //     required: true,
                                    // })}
                                    />
                                </div>

                                <div className="col-md-4 col-lg-2 mb-3 d-flex flex-column">
                                    <label>Upload File</label>
                                    <input
                                        type='file'
                                        className="form-control-file"
                                        onChange={(e) => handleCsvUpload(e)}
                                        accept='.csv'
                                        ref={ref}
                                    />
                                </div>

                                {
                                    excelDataSource.length > 0 && simTableData ? <div className="col-md-12 mt-2 mb-3">
                                        <Table columns={excelColumns} loading={validateSimDataLoading} data={excelDataSource} pagination={true} />
                                    </div> : ''
                                }



                                <div className="col-md-12 mb-3 text-right">

                                    <Button title="Cancel" buttonClass={`btn btn-danger btn-sm py-2 mr-2`} onClick={handleShowListPurchaseUi} buttonType='button' />
                                    {/* {excelDataSource.length > 0 ? <Button title="Submit" style="minWidth:80px" buttonClass={`btn btn-primary btn-sm py-2`} loading={SimPurchaseState.savePurchaseLoading} buttonType='submit' /> : ''} */}
                                    <Button title="Submit" disable={excelDataSource.length == 0 || csvError != '' || SimPurchaseState.validateSimData != null ? true : false} style="minWidth:80px" buttonClass={`btn btn-primary btn-sm py-2`} loading={SimPurchaseState.savePurchaseLoading} buttonType='submit' />

                                </div>



                            </div>
                        </form>

                    </CardLayout>

                </> : ''
            }


            {
                showSimPurchaseDetail ? <>
                    <CardLayout title="View Sim Purchase">

                        <div className='row'>
                            <div className='col-md-6 od-2'>
                                <SearchBar onFilter={handleFilterChange} filterText={filterText} />
                            </div>
                            <div className='col-md-6 text-right'>
                                <button type="button" className="btn btn-danger mr-2 px-5" onClick={() => setShowSimPurchaseDetail(false)}>BACK</button>
                            </div>
                        </div>

                        <Table columns={simDetailsColumns} loading={SimPurchaseState.getSimPurchaseDetailsLoading} data={simDetailsDataSource} pagination={true} />

                    </CardLayout>
                </> : ''
            }


        </>

    )
}

const Styles = {
    modalContent: {
        display: "flex",
        flexDirection: "column",
        alignItems: 'center'
    }
}

export default SimPurchase
