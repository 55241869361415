import React, { useMemo, useState } from 'react'
import ArrowRightLineIcon from 'remixicon-react/ArrowRightLineIcon'
import { useForm } from "react-hook-form";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CloseCircleLineIcon from 'remixicon-react/CloseCircleLineIcon';
import CardLayout from '../../../components/utils/CardLayout'
import Table from '../../../components/utils/Table';
import Button from '../../../components/utils/Button'
import Styles from './orderRefund.module.css'
import PopConfirm from '../../../components/utils/Popover';
import orderRefundAction from '../../../redux/orderRefund/actions';
import Modal from '../../../components/utils/Model';


const { getOrderByIccId, resetOrderRefund, orderRefundRequest } = orderRefundAction;

const OrderRefund = () => {
    const dispatch = useDispatch();
    const orderRedundState = useSelector((state) => state.orderRefund);

    const data = useMemo(() => {
        if (orderRedundState.getOrderByIccIdList.length > 0) {
            let rows = orderRedundState.getOrderByIccIdList.map((item, index) => {
                return {
                    key: index + 1,
                    orderNumber: item.ID,
                    planName: item.PlanName,
                    amount: `£ ${item.PlanPrice}`,
                    iccId: item.ICCID,
                    data: item.Data,
                    validity: `${item.PlanDays} Days`,
                    country: item.CountryName,
                    status: item.SimStatus,
                    refund: <>
                        <PopConfirm
                            title="Are you sure to refund request to admin"
                            okText="Yes"
                            cancelText="No"
                            placement="topRight"
                            onConfirm={() => handleRedundRequestToAdmin(index)}
                        >
                            <Link className="deleteBtn" to="# ">
                            <span data-toggle="tooltip" data-placement="top" title="Order refund"><ArrowRightLineIcon size="1.2em" style={{ color: "#0094b3", cursor: 'pointer' }} /></span>
                            </Link>
                        </PopConfirm>
                    </>
                };
            });
            return rows;
        } else {
            return [];
        }
    }, [orderRedundState.getOrderByIccIdList]);

    const { register, handleSubmit, formState: { errors }, watch, reset } = useForm({});

    const onSubmit = (data) => {
        dispatch(getOrderByIccId(data));
    };

    const handleRedundRequestToAdmin = (index) => {
        let selectedRow = orderRedundState.getOrderByIccIdList[index];
        let request = {
            orderQuantityDetailID: selectedRow.OrderQuantityDetailID,
            packageID: "1",
            amount: selectedRow.Amount,
            ICCID: selectedRow.ICCID,
            orderID: selectedRow.OrderID,
            planID: selectedRow.planID,
            aggregatorID: selectedRow.AggregatorID,
            planVariantID: selectedRow.PlanVariantID
        };
        dispatch(orderRefundRequest(request));
    }

    const closeAlertModal = () => {
        dispatch(resetOrderRefund());
    }

    const columns = [
        { displayName: '#', key: 'key' },
        { displayName: 'Plan Name', key: 'planName' },
        { displayName: 'Amount', key: 'amount' },
        { displayName: 'ICCID', key: 'iccId' },
        { displayName: 'Data', key: 'data' },
        { displayName: 'Validity', key: 'validity' },
        { displayName: 'Country', key: 'country' },
        { displayName: 'Refund', key: 'refund' },
        { displayName: 'Status', key: 'status' },

    ];

    return (
        <>
            <Modal
                showModal={orderRedundState.getOrderByIccIdModal && orderRedundState.getOrderByIccIdError}
                title="Failed"
                onHide={() => closeAlertModal()}
                onSaveChanges={() => closeAlertModal()}
                showFooter={false}
                width={400}
                alignment='modal-dialog-centered'
            >
                <div style={Style.modalContent}>
                    <CloseCircleLineIcon size="4em" color='#e74c3c' />
                    <p className='text-dark'>{orderRedundState.getOrderByIccIdError}</p>
                </div>

            </Modal>

            <CardLayout title="Order Refund">
                <form className="form-row" onSubmit={handleSubmit(onSubmit)}>
                    <div className="col-md-4 mb-3">
                        <label>ICCID</label>
                        <input type="text" placeholder="Enter ICCID" className="form-control" name="ICCID" {...register("ICCID", {
                            required: true,
                            pattern: /^\d{19}$/,
                        })} />
                        {errors.ICCID?.type === "required" && (<div className="invalid-feedback">ICCID is required !</div>)}
                        {errors.ICCID?.type === "pattern" && (<div className="invalid-feedback">Invalid ICCID !</div>)}
                    </div>
                    <div className="col-md-2 mb-3  mt-md-4">

                        <Button title="Search" loading={orderRedundState.getOrderByIccIdLoading} buttonClass={`btn Activity_searchBtn__GlBtC mt-1 btn-primary btn-md`} buttonType='submit' />

                    </div>
                </form>
                <Table columns={columns} data={data} loading={orderRedundState.getOrderByIccIdLoading} pagination={true} />
            </CardLayout>
        </>
    )
}


const Style = {
    modalContent: {
        display: "flex",
        flexDirection: "column",
        alignItems: 'center'
    }
}

export default OrderRefund;
